
.App {
  font-family: 'Khmer', sans-serif;
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  color: red;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Khmer', sans-serif;
  font-family: 'Noto Sans Khmer', sans-serif;
  src: local('Myriad Pro Regular'), url('https://fonts.googleapis.com/css2?family=Khmer&family=Noto+Sans+Khmer&display=swap') format('woff');
}